import React, { useEffect, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import { Button, Modal, message } from "antd";
import styles from "./CollaborationPaymentStyles.module.css";
import tickPop from "../../../../assests/tickPop.png";
import FailedTick from "../../../../assests/customDeleteMark.svg";
import confettiIMG from "../../../../assests/confettiIMG.png";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import bgImg from "../../../../assests/bgEvent.png";
import { _POST } from "../../../../common/commonFunc";
import { APPLICATION_ROUTES } from "../../../../constant/ApplicationRoutes";
import Cookies from "js-cookie";

interface RouteParams {
  collaboration_id: string;
  charge_id: string;
  contract_id: string;
  contract_mode: string;
}

function CollaboartionPaymentConfirmationPage(ids: any) {
  const { collaboration_id, contract_id, contract_mode } =
    useParams<RouteParams>();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const tap_id = queryParams.get("tap_id");
  const data = queryParams.get("data");
  console.log(
    "checking params",
    collaboration_id,
    contract_id,
    contract_mode,
    tap_id
  );

  const [isFailureModalVisible, setIsFailureModalVisible] = useState(false);
  const [failedMessage, setFailedMessage] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] =
    useState<boolean>(false);

  console.log("chekign fielded message", failedMessage);

  const showSuccessModal = () => {
    setIsSuccessModalVisible(true);
  };

  useEffect(() => {
    const apiUrl = `https://development.theoctopus.ai:8443/payment/collaboration/getPaymentById?chargeId=${tap_id}&collaboration_id=${collaboration_id}&contract_id=${contract_id}&contract_mode=${contract_mode}`;

    axios
      .get(apiUrl, {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
          "x-refresh-token": Cookies.get("refreshToken") ?? "",
        },
      })
      .then(async ({ data }) => {
        if (data.status === false) {
          setFailedMessage(data.message || "An error occurred");
          setIsFailureModalVisible(true);
        } else {
          if (data && data?.status === true) {
            showSuccessModal();
          }
        }
      })
      .catch((error) => {
        setFailedMessage(
          error.response?.data.message || "An unexpected error occurred"
        );
        setIsFailureModalVisible(true);
        console.error("Error fetching payment data:", error);
      });
  }, [collaboration_id, tap_id]);

  const history = useHistory();

  const handleSuccessModalClose = () => {
    setIsSuccessModalVisible(false);
    history.push("/supplier/collabration");
  };

  const handleFailureModalClose = () => {
    setIsFailureModalVisible(false);
    history.push("/supplier/collabration");
  };

  return (
    <div
      style={{
        padding: "24px",
        borderRadius: "1px",
        backgroundImage: `url(${bgImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {failedMessage === false && (
        <Dialog
          open={isSuccessModalVisible}
          onClose={handleSuccessModalClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent
            className={styles.successModalContent}
            style={{
              background: `url(${confettiIMG})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backdropFilter: "blur(2px)",
            }}
          >
            <img src={tickPop} alt="" />
            <h2>Thank You!</h2>
            <p>Your Advance Payment is sent to service provider</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                type="primary"
                onClick={handleSuccessModalClose}
                style={{ marginTop: "24px" }}
                className={styles.button}
              >
                Go back to collaboration
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
      {failedMessage !== false && (
        <Dialog
          open={isFailureModalVisible}
          onClose={handleFailureModalClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent
            className={styles.failedModalContent}
            style={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backdropFilter: "blur(2px)",
            }}
          >
            <img src={FailedTick} alt="" />
            <h2>Payment Failed</h2>
            <p>{failedMessage}</p>
            {/* <p>You've received 100 OC point</p> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                type="primary"
                onClick={handleFailureModalClose}
                style={{ marginTop: "24px" }}
              >
                Go to Home
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default CollaboartionPaymentConfirmationPage;
