import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  TablePagination,
  styled,
  TooltipProps,
  Tooltip,
  tooltipClasses,
  Typography,
  Box,
  CardContent,
} from "@mui/material";
import { Button, Drawer, message, notification, Popconfirm, Space } from "antd";
import { Visibility } from "@mui/icons-material";
import Styles from "../../OrderPages/freelancerComponets/Table.module.css";
import { _PATCH, _POST } from "../../../common/commonFunc";
import { useHistory } from "react-router";
import Cross from "../../../assests/customIcons/Cross";
import axios from "axios";
// import RejectContractModal from "./RejectContractModal";

const ExpandedRowSupplierTable = ({ headers, data, fetchData, user }: any) => {
  console.log(" exp row data is : ", data)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2);
  const [open, setOpen] = useState(false);
  const [collabDetails, setCollabDetails] = useState<any>()
  const history = useHistory();

  const [sortedData, setSortedData] = useState<any>([]);

  useEffect(() => {
    const sorted = [...data].sort((a, b) => {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    });
    setSortedData(sorted);
  }, [data]);

  useEffect(() => {
    console.log("sortedData is :", sortedData)
  }, [sortedData])

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#30263E",
      boxShadow: "0px 8px 24px 0px #422F9B1A",
      fontSize: 12,
      maxWidth: "271.44px",
    },
  }));

  const handleAdvancePayment = (collab: any) => {
    console.log("collab is : ", collab)
    history.push(`/supplier/advance-payment/${collab.request_id}?contract_id=${collab?.contract_id}&contract_mode=${collab?.contract_mode}`)
  };

  const onClose = () => {
    setOpen(false);
    setCollabDetails(null)
  };

  const showDrawer = (row: any) => {
    console.log("value of row is : ", row)
    setCollabDetails(row);
    setOpen(true);
  };

  const handleTerminateContract = async (row: any) => {
    // console.log("row is : ", row)
    try {
      const res = await axios.patch(
        `https://development.theoctopus.ai:8443/supplier/terminateContract?contract_id=${row?.contract_id}&contract_mode=${row.contract_mode}`,
        {},
        { headers: {
          "x-access-token":
            localStorage.getItem("jwtToken") ||
            localStorage.getItem("Temp_jwtToken"),
        }}
      );
  
      if(res.status){
      fetchData()
    }
    } catch (error) {
      console.error("Error fetching product data:", error);
      return {};
    }
  }

  const renderPaymentStatus = (advance_paid: boolean, contract_type: any, contract_status: string) => {
    if (contract_status === "rejected" || contract_status === "pending") {
      return (
        <Typography>
          -
        </Typography>)
    }
    if (contract_type === "commission_percentage") {
      return (
        <Typography>
          -
        </Typography>)
    } else {
      if (advance_paid) {
        return (
          <Typography variant="caption" style={{ color: "green" }}>
            Advance Paid
          </Typography>)
      } else {
        return (
          <Typography variant="caption" style={{ color: "red" }}>
            Advance Pending
          </Typography>)
      }
    }
  }


  function getPlainText(text: any) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, "text/html");
    return doc.body.textContent || "";
  }


  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const formatDate = (unixTimestamp: any) => {
    const date = new Date(parseInt(unixTimestamp) * 1000);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  const renderId = (id: string) => {
    return (
      <LightTooltip
        title={
          <>
            <div style={{ fontWeight: "bold" }}>Click to Copy :</div>
            <br />
            <div>{id}</div>
          </>
        }
        placement="bottom"
        arrow
      >
        <span
          onClick={(event) => copyToClipboard(id, event)}
          style={{
            cursor: "pointer",
          }}
        >
          {formatId(id)}
        </span>
      </LightTooltip>
    );
  };

  const renderDesc = (text: string) => {
    return (
      <LightTooltip
        title={<>{getPlainText(text ?? " ")}</>}
        placement="bottom"
        arrow
      >
        <div>{text ? formatDesc(getPlainText(text ?? " ")) : "-"}</div>
      </LightTooltip>
    );
  };


  const formatDesc = (desc: any) => {
    const prefix = desc?.substring(0, 20);
    if (desc?.length > 20) {
      return `${prefix}${" ... "}`;
    }
    return `${prefix}`;
  };


  const formatId = (id: any) => {
    const prefix = id?.substring(0, 7);
    const start = id?.substring(8, 10);
    const end = id?.substring(id.length - 2);
    return `${prefix}${" : "}${start}..${end}`;
  };

  const copyToClipboard = async (text: any, event: any) => {
    event.stopPropagation();
    try {
      await navigator.clipboard.writeText(text);
      notification.success({ message: "Request ID copied to clipboard" });
    } catch (err) {
      notification.error({ message: "Failed to copy Request ID" });
    }
    event.stopPropagation();
  };

  return (
    <>
      <TableContainer component={Paper} className={Styles.tableContainer}>
        <div className={Styles.customHeader}>
          <Table className={Styles.table}>
            <TableHead className={Styles.tableHeader}>
              <TableRow>
                {headers.map((header: any) => (
                  <TableCell
                    key={header.id}
                    style={{
                      width: header.width,
                      textAlign: "center",
                    }}
                  >
                    {header.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          </Table>
        </div>

        <div className={Styles.customHeader}>
          <Table className={Styles.table}>
            <TableBody className={Styles.tableBody}>
              {sortedData?.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={headers.length}
                    style={{ textAlign: "center" }}
                  >
                    <h1> No data available</h1>
                  </TableCell>
                </TableRow>
              ) : (
                sortedData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row: any, index: any) => (
                  <TableRow key={index}>
                    {headers.map((header: any) => (
                      <TableCell
                        key={`${header.id}-${index}`}
                        style={{
                          width: header.width,
                          textAlign: "center",
                        }}
                      >
                        {header.id === "contract_id" ? (
                          renderId(row?.contract_id)
                        ) : header.id === "start_date" ? (
                          formatDate(row?.collaboration_start_date)
                        ) : header.id === "end_date" ? (
                          formatDate(row?.collaboration_end_date)
                        ) : header.id === "contract_text" ? (
                          renderDesc(row?.contract_text)
                        ) : header.id === "contract_mode" ? (
                          row?.contract_mode === "product_based" ? "Product Based" : "Non Product Based"
                        ) : header.id === "contract_status" ? (
                          <p style={{
                            color: row?.contract_status === "pending" ? "#1677ff" :
                              row?.contract_status === "accepted" ? "limegreen" :
                                "red"
                          }}> <Typography variant="caption">{row?.contract_status?.toUpperCase()}</Typography></p>
                        ) : header.id === "contract_type" ? (
                          row?.contract_type && row?.contract_type === "commission_percentage" ? "Commission Percentage" : "Fixed Amount"
                        ) : header.id === "fixed_amount" ? (
                          row?.contract_type !== "commission_percentage" ? <>
                            <Typography variant="caption">
                              <b>BHD</b> {row?.fixed_amount}
                            </Typography>{" "}
                          </> : "-"
                        ) : header.id === "payment_status" ? (
                          renderPaymentStatus(row?.is_advance_amount_paid, row?.contract_type, row?.contract_status)
                        ) : header.id === "action" ? (
                          // renderActionButtons(row, row?.contract_mode+"_"+row?.contract_status)

                         row?.contract_status === 'terminated' ? "-": (  <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {(row.contract_mode === "product_based" && row.contract_type !== 'commission_percentage') ?
                              (row.is_advance_amount_paid && row.advance_payment_ref_id) ?
                                ""
                                :
                                <Button className={Styles.addButton} onClick={() => handleAdvancePayment(row)} disabled={!row?.is_signed}>Pay</Button>
                              : ""
                            }
                            {(row.contract_mode === "non_product_based") ?
                              (row?.is_advance_amount_paid && row?.advance_payment_ref_id) ?
                                ""
                                :
                                <Button className={Styles.addButton} onClick={() => handleAdvancePayment(row)} disabled={!row?.is_signed}>Pay</Button>
                              : ""
                            }
                            {row?.contract_status === 'accepted' && !row.is_advance_amount_paid &&
                              <Popconfirm
                                title="Are you sure you want to terminate this contract?"
                                onConfirm={() => { handleTerminateContract(row) }}
                                okText="Yes"
                                cancelText="No"
                              >
                               <Button className={Styles.reschedule}>
                                  Terminate
                                </Button>
                              </Popconfirm>}
                            <Button className={Styles.eye} onClick={() => showDrawer(row)}>
                              <Visibility />
                            </Button>
                          </div>)
                        ) : (
                          row[header.id]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <div className={Styles.tableFooter}>
            <div className={Styles.viewControls}>
              {" "}
            </div>
            <TablePagination
              component="div"
              count={sortedData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[2, 5, 10, 25, 50, 100, 200]}
              className={Styles.pagination}
            />
          </div>
        </div>
      </TableContainer>

      <Drawer
        title="      "
        placement="right"
        onClose={onClose}
        open={open}
        key="right"
        width={"550px"}
        closable={false}
        extra={
          <Space style={{ zIndex: 100 }}>
            <Button onClick={onClose} className="elevatedElement iconBtn">
              <Cross />
            </Button>
          </Space>
        }
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography
              component="div"
              variant="h5"
              fontWeight={700}
              gutterBottom
            >
              Contract Details
            </Typography>
            <Typography
              variant="subtitle2"
              color={"text.secondary"}
              sx={{ display: "flex", flexDirection: "row" }}
              gutterBottom
            >
              Contract ID:{" "}
              <div style={{ color: "black", marginLeft: "3px" }}>
                {collabDetails?.contract_id}
              </div>
            </Typography>
            <Typography
              variant="subtitle2"
              color={"text.secondary"}
              sx={{ display: "flex", flexDirection: "row" }}
              gutterBottom
              mb={3}
            >
              Contract Text:{" "}
              <div style={{ color: "black", marginLeft: "3px" }}>
                {getPlainText(collabDetails?.contract_text)}
              </div>
            </Typography>

            <Box
              component="div"
              sx={{
                display: "flex",
                flexDirection: "column",
                maxHeight: "150px",
                overflowY: "scroll",
              }}
            >
              <Typography
                variant="caption"
                color={"text.secondary"}
                gutterBottom
              >
                {collabDetails ? (
                  <>

                    <div className={Styles.contractDiv}>

                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                        <Typography variant="body2" fontWeight={"bold"} color={"black"}> Fixed Amount </Typography>
                        <Typography variant="body2" fontWeight={"bold"} color={"grey"} marginLeft={1}>BHD {collabDetails?.fixed_amount}</Typography>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                        <Typography variant="body2" fontWeight={"bold"} color={"black"}> Amount with VAT </Typography>
                        <Typography variant="body2" fontWeight={"bold"} color={"grey"} marginLeft={1}>BHD {collabDetails?.total_amount_including_vat}</Typography>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                        <Typography variant="body2" fontWeight={"bold"} color={"black"}> Advance Amount Percentage </Typography>
                        <Typography variant="body2" fontWeight={"bold"} color={"grey"} marginLeft={1}> {collabDetails?.advance_amount_percentage} %</Typography>
                      </div>

                      {collabDetails?.is_advance_amount_paid ?
                        (
                          <Typography variant="body2" color={"green"}>Advance amount <b>BHD {collabDetails?.actual_amount_paid}</b> is paid</Typography>
                        ) :
                        <>
                          <Typography variant="subtitle2" color={"red"}>Advance payment of <b>BHD {collabDetails?.advance_amount_to_pay}</b>  is pending</Typography>
                        </>
                      }
                    </div>

                  </>
                ) : (
                  "No Data Available"
                )}
              </Typography>
            </Box>




            <Box
              sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center  " }}
              marginTop={3}
              gap={2}
            >
              <Popconfirm
                title="Are you sure you want to end this collaboration?"
                onConfirm={() =>
                  //   confirmEndCollab(collabDetails?.request_id)
                  ""
                }
                okText="Yes"
                cancelText="No"
              >
                <Button className={Styles.buttonDark}>End Collaboration</Button>
              </Popconfirm>
              <Popconfirm
                title="Are you sure you want to extend this collaboration?"
                onConfirm={() =>
                  //   confirmExtendCollab(collabDetails?.request_id)
                  ""
                }
                okText="Yes"
                cancelText="No"
              >
                <Button className={Styles.buttonFilled}>
                  Extend Collaboration
                </Button>
              </Popconfirm>
            </Box>
          </CardContent>
        </Box>
      </Drawer>

    </>
  );
};

export default ExpandedRowSupplierTable;
