import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Button,
  Calendar,
  Divider,
  Form,
  Input,
  Modal,
  message,
  notification,
  Typography,
  Radio,
  Skeleton,
} from "antd";
import "../ServiceBooking/Booking.css";
import styles from "../ServiceConfirmation/ServiceConfirmationPage.module.css";
import tickPop from "../../../assests/tickPop.png";
import confettiIMG from "../../../assests/confettiIMG.png";
import FailedTick from "../../../assests/customDeleteMark.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useHistory, useParams } from "react-router";
import { _GET, _MGET, _POST } from "../../../common/commonFunc";
import Styles from "../ServiceBooking/Service.module.css";
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar";
import LocationSelector from "./AddressSelection";
import axios from "axios";
import _ from "lodash";
import { BASE_URL } from "../../../constant/ApplicationRoutes";
import TermsAndConditions from "../../TermsAndConditions/TermsAndConditions";
import OCPoint from "../../../assests/customIcons/OCPoint";
import Percentage from "../../../assests/customIcons/Percentage";
import PrivacyPolicy from "../../TermsAndConditions/PrivacypolicyModal";
import MobileTopDashboardNavBar from "../../../common/DashboardNavBar/mobileTopBar";
import grdCirculeIcon from "../../../assests/circulegrd.svg";
import { useDispatch, useSelector } from "react-redux";
import { setServiceDetails } from "../redux/actions/action";
import PlusIcon from "../../../assests/PlusIcon.svg";
import MinusIcon from "../../../assests/MinusIcon.svg";
import { useLocation } from "react-router";
const { TextArea } = Input;

const ProductBooking = () => {
  const history = useHistory();
  const productOrderDetails = useSelector(
    (state: any) => state.productOrderDetails.productOrderSelected
  );
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [selectedApiTime, setApiSelectedTime] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const { serviceId } = useParams<{ serviceId: string }>();
  const location = useLocation<any>();
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [cards, setCards] = useState<any>([]);
  const [isTCChecked, setisTCChecked] = useState(false);
  const [isPPChecked, setisPPChecked] = useState(false);
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [selectedPayType, setSelectedPayType] = useState("Card");
  const [selectedCard, setSelectedCard] = useState(null);
  const [serviceData, setServiceData] = useState<any>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedLocationValue, setSelectedLocationValue] =
    useState("default address");
  const [currentDate, setCurrentDate] = useState("");
  const [assignedData, setAssignedData] = useState<any>([]);
  const [selectedAddressValue, setSelectedAddressValue] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [billingSummary, setBillingSummary] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isFailureModalVisible, setIsFailureModalVisible] = useState(false);
  const [failedMessage, setFailedMessage] = useState("");
  const [isPaidSucess, setIsPaidSucess] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const quantity: any = location.state.quantity;
  const color: any = location.state.color;
  const size: any = location.state.size;
  const productData = location.state.productData;

  console.log("Single Checkout product data is : ", productData);

  const [ppModalVisible, ppSetModalVisible] = useState(false);
  const [visibleCardsCount, setVisibleCardsCount] = useState(3);
  const [count, setCount] = useState<any>(quantity);
  const [addressMainData, setAddressMainData] = useState<any>([0]);
  const AddressHandler = async () => {
    const userData = JSON.parse(localStorage.getItem("userInfo") || "");
    const getData: any = await _GET(
      "userProfile/getUserAllAddress/" + userData.id,
      true
    );
    console.log("Val of getData adddress is", getData);
    console.log("bjb", _.get(getData, "data[0][user_addresses]", []));
    if (_.get(getData, "data[0][user_addresses]", []).length > 0) {
      setAddressMainData(_.get(getData, "data[0][user_addresses]", []));
    } else {
      setAddressMainData([]);
    }
  };

  React.useEffect(() => {
    console.log("VALUE OF COLOR AND SIZE IS", color, size);
    AddressHandler();
  }, []);

  React.useEffect(() => {
    console.log("productOrderDetails", productOrderDetails);
  }, [productOrderDetails]);

  function getInventoryId(assignedData: any, color: any, size: any) {
    // Check if assignedData is an array and has at least one element
    if (Array.isArray(assignedData) && assignedData.length > 0) {
      // Iterate through each product object in the assignedData array
      for (let i = 0; i < assignedData.length; i++) {
        const product = assignedData[i];
        // Check if the product object has an 'inventory' array
        if (
          product.hasOwnProperty("inventory") &&
          Array.isArray(product.inventory)
        ) {
          // Iterate through each inventory object in the 'inventory' array
          for (let j = 0; j < product.inventory.length; j++) {
            const inventory = product.inventory[j];
            // Check if both color and size match
            if (
              inventory.color.toLowerCase() === color.toLowerCase() &&
              inventory.size === size
            ) {
              // Return the inventory_id if a match is found
              return inventory.inventory_id;
            }
          }
        }
      }
    }

    return null;
  }

  const decrementCount = () => {
    if (count > 1) {
      setCount((prevCount: any) => prevCount - 1);
    }
  };

  const incrementCount = () => {
    var CurrentInventory = serviceData.inventory.filter(
      (item: any) => item.size === size && item.color === color
    );
    console.log("INVENTORY IAM GETTING IS", CurrentInventory);

    if (
      count < CurrentInventory[0]?.quantity &&
      CurrentInventory[0]?.quantity > 0
    ) {
      setCount((prevCount : number) => prevCount + 1);
    } else {
      notification.warning({
        message: "Stock Limit Reached",
        description: "You cannot add more items than available stock.",
      });
    }
  };

  const ppShowModal = () => {
    ppSetModalVisible(true);
  };

  const ppCloseModal = () => {
    ppSetModalVisible(false);
  };

  const handleSuccessModalClose = () => {
    setIsSuccessModalVisible(false);
  };
  const handleFailureModalClose = () => {
    setIsFailureModalVisible(false);
  };

  useEffect(() => {
    const fetchAssignedData = async () => {
      try {
        const response = await axios.get(
          `https://development.theoctopus.ai:8443/supplier/assignedProducts/${serviceId}`,
          {
            headers: {
              "x-access-token": localStorage.getItem("jwtToken"),
            },
          }
        );

        if (response.data && response.data.data) {
          const fetchedService = response.data.data[0];
          setAssignedData(fetchedService);
          console.log("ASSIGNED PRODUCT DATA ON BOOOKING IS", fetchedService);
        } else {
          console.error("Service data not found in response");
        }
      } catch (error) {
        console.error("Error fetching service data:", error);
      }
    };
    fetchAssignedData();
  }, [serviceId]);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const payTypes = ["Card", "Benefit Pay"];

  const fetchBillingSummary = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}supplier/productBillingSummary?productId=${serviceId}&quantity=${count}`,
        {
          headers: {
            "x-access-token": localStorage.getItem("jwtToken"),
          },
        }
      );
      console.log("Billing response is", response);
      if (response.data) {
        setBillingSummary(response.data.data);
        console.log("Billing response is", billingSummary);
      }
    } catch (error) {
      console.error("Error fetching billing summary:", error);
    }
  };

  useEffect(() => {
    fetchBillingSummary();
  }, [selectedLocationValue, count]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paymentStatus = queryParams.get("status");

    if (paymentStatus === "true") {
      setShowSuccessModal(true);
      setIsSuccessModalVisible(true);
    }
  });

  useEffect(() => {
    const fetchServiceData = async () => {
      try {
        const response = await axios.get(
          `https://development.theoctopus.ai:8443/supplier/product/fetch/product?product_id=${serviceId}`,
          {
            headers: {
              "x-access-token": localStorage.getItem("jwtToken"),
            },
          }
        );

        if (response.data && response.data.data) {
          console.log("BOOKING PAGE PRODUCT DATA IS", response);
          setServiceData(response.data.data[0]);

          setSelectedLocationValue("default address");
          fetchBillingSummary();
        } else {
          console.error("Service data not found in response");
        }
      } catch (error) {
        console.error("Error fetching service data:", error);
      }
    };

    fetchServiceData();
  }, [serviceId]);

  const handleAdditionalNotesChange = (notes: any) => {
    setAdditionalNotes(notes);
  };

  const showTermsModal = () => {
    setModalVisible(true);
  };

  const handleOk = () => {
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    if (serviceData) {
    }
  }, [serviceData]);

  const myCards = async () => {
    try {
      const res = await _GET(`payment/getUserAllCard`, true);

      if (res.status === true) {
        const uniqueCards = _.get(res, "data", []);
        setCards(uniqueCards);
      } else {
        console.error("Error fetching cards: ", res.message);
      }
    } catch (error) {
      console.error("Error fetching cards: ", error);
    }
  };
  useEffect(() => {
    const getTodaysDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      let month = today.getMonth() + 1;
      let day = today.getDate();
      const formattedDate = `${year}-${month}-${day}`;
      setCurrentDate(formattedDate);
    };

    getTodaysDate();
  }, []);

  useEffect(() => {
    myCards();
  }, []);

  const CardComponent = ({ card, onSelect, isSelected }: any) => {
    if (!card) {
      return null;
    }

    const formattedCardNumber = `${card?.first_eight.substring(
      0,
      8
    )} **** ** ${card?.last_four.substring(2)}`;
    return (
      <div
        className={Styles.cardContainer}
        onClick={() => onSelect(isSelected ? null : card?.id)}
      >
        <div
          className={`${Styles.radioCircle} ${
            isSelected ? Styles.selectedCard : ""
          }`}
          dangerouslySetInnerHTML={{
            __html: isSelected
              ? `<img src="${grdCirculeIcon}" alt="Circle Icon" />`
              : "",
          }}
        />
        <div className={Styles.cardDetails}>
          <div className={Styles.cardName}>{card?.name}</div>
          <div className={Styles.cardInfo}>
            <span className={Styles.cardBrand}>{card?.brand} </span> |{" "}
            <span className={Styles.cardNumber}>{formattedCardNumber}</span>
          </div>
        </div>
      </div>
    );
  };
  const handleLocationChange1 = (location: string, address: string) => {
    console.log("location address", { address, location });
    setSelectedLocationValue(location);
    setSelectedAddressValue(address);
  };

  function addressStringToObject(address: string) {
    const parts = address.split(",").map((part) => part.trim());

    return {
      address_name: parts[0] || "",
      building_no: parts[1] || "",
      floor: parts[2] || "",
      address: parts[3] || "",
      landmark: parts[4] || "",
      pincode: parts[5] || "",
      city: parts[6] || "",
      state: parts[7] || "",
      country: parts[8] || "",
    };
  }
  const addressObject = addressStringToObject(selectedAddressValue);

  const handleSlotReserveClick = () => {
    setShowConfirmationModal(true);
  };

  const parseDuration = (durationStr: any) => {
    const regex = /(\d+)d:(\d+)h:(\d+)m/;
    const matches = durationStr.match(regex);
    if (!matches) return 0;

    const days = parseInt(matches[1]);
    const hours = parseInt(matches[2]);
    const minutes = parseInt(matches[3]);

    return (days * 24 * 60 + hours * 60 + minutes) * 60;
  };

  // const addressObject = addressStringToObject(selectedAddressValue);

  const handleBooking = async () => {
    // if (selectedCard === null && cards.length !== 0) {
    //   notification.error({
    //     message: "No Card Selected",
    //     description: "Please select a Card before booking.",
    //     placement: "topLeft",
    //   });
    //   return;
    // } else if (selectedCard === null) {
    //   notification.success({
    //     message: "Add card on payment page",
    //     description: "Please add a Card on payment page for further booking.",
    //     placement: "topRight",
    //   });
    // }

    if (!selectedAddressValue) {
      notification.error({
        message: "No Address Selected",
        description: "Please select an address before booking.",
        placement: "topLeft",
      });
      return;
    }

    const bookingData = {
      total_product_price_amount: billingSummary.productTotalAmount,
      total_product_price_ccy: billingSummary.amountToPayCurrency,
      total_discount_amount: billingSummary.discountAmountToPay,
      total_discount_ccy: billingSummary.discountAmountToPayCurrency,
      products: [
        {
          product_id: serviceData.product_id,
          inventory_id: productData?.inventory[0]?.inventory_id,
          supplier_id: productData?.supplier_info?.supplier_id,
          service_provider_id: productData.freelancer_info.id,
          ordered_quantity: count,
          product_total_price: billingSummary.productTotalAmount,
          product_discounted_price:
            billingSummary.productTotalAmount -
            billingSummary.discountAmountToPay,
          product_vat_amount: billingSummary.vatAmountToPay,
          product_discount_amount: billingSummary.discountAmountToPay,
          product_discount_percentage: billingSummary.discountPercentage,
          product_vat_percentage: billingSummary.vatPercentage,
          product_amount_to_pay: billingSummary.amountToPay,
          product_shipping_fees: billingSummary.shippingFees,
        },
      ],

      customer_address: addressObject,
      total_vat_amount: billingSummary.vatAmountToPay,
      total_vat_ccy: billingSummary.vatAmountCurrency,
      total_shipping_amount: billingSummary.shippingFees,
      total_shipping_amount_ccy: billingSummary.shippingFeesCurrency,
      total_amount_paid: billingSummary.amountToPay,
      total_amount_paid_ccy: billingSummary.amountToPayCurrency,
    };

    console.log("Payload for product booking : ", bookingData);

    const response = await _POST(
      `supplier/customer/buy/product`,
      bookingData,
      false
    );

    if (response && response.status) {
      const paymentPayloadData = {
        // email: userInfo.email,
        amount: billingSummary.amountToPay,
        currency: "BHD",
        description: "Payment has been made",
        order_id: _.get(response, "data.order_id", ""),
        card_id: selectedCard,
      };

      const data = await _POST(
        `payment/product/createCharge`,
        paymentPayloadData,
        true
      );

      window.location.href = data.data.redirect_url;
    }
  };

  return (
    <>
      <MobileTopDashboardNavBar />
      <div className={`customLayoutSideContainer ${Styles.servicesContainer}`}>
        <DashboardNavBar />
        <div className={`${Styles.serviceMainContainer} bookingPageContainer`}>
          {serviceData && (
            <div className={Styles.infoContainer}>
              <div className="infoContainer">
                {serviceData &&
                  serviceData.product_images !== null &&
                  serviceData.product_images.length > 0 && (
                    <img
                      src={serviceData.product_images[0]}
                      className="smallimg"
                      alt=""
                    />
                  )}
                <div className="serviceBookingInfo">
                  <h3 className="bold xlSize" onClick={() => history.goBack()}>
                    {serviceData.product_name}
                  </h3>
                  <p className="largeSize">
                    <span className="currency">BHD</span>{" "}
                    <span className="titlePrice">
                      {" "}
                      {serviceData.product_unit_price}
                    </span>{" "}
                    | {serviceData.product_category}
                  </p>
                </div>
              </div>

              <div className={Styles.cartCount}>
                <button onClick={decrementCount} className={Styles.CartButton}>
                  <img src={MinusIcon} alt="Decrease" />
                </button>
                <span className={Styles.countDisplay}>
                  {String(count).padStart(2, "0")}
                </span>
                <button onClick={incrementCount} className={Styles.CartButton2}>
                  <img src={PlusIcon} alt="Increase" />
                </button>
              </div>
            </div>
          )}
          {serviceData && (
            <div className="bookingMainContainer">
              <div className="date-picker-containerPar">
                <div className="date-picker-container">
                  <div className={Styles.locationPref}>
                    <h3 className="bold xlSize">Shipping Address</h3>

                    {addressMainData && addressMainData.length > 0 ? (
                      <LocationSelector
                        onLocationChange={handleLocationChange1}
                        serviceLocation={JSON.stringify(addressMainData[0])}
                        serviceAtCustomerLocation={"yes"}
                        setCoords={() => {}}
                      />
                    ) : (
                      <div
                        style={{
                          marginTop: "2rem",
                          marginBottom: "2rem",
                        }}
                      >
                        No Address Found
                      </div>
                    )}
                  </div>
                  <div>
                    <section
                      className="paySelection"
                      style={{ marginBottom: "1rem" }}
                    >
                      <h3 className="bold xlSize">Payment method</h3>
                      <div className="payTypeSelection">
                        {payTypes &&
                          payTypes.map((type) => (
                            <button
                              key={type}
                              className={`payTypeBtn ${
                                type === selectedPayType ? "payActiveBtn" : ""
                              }`}
                              onClick={() => setSelectedPayType(type)}
                              style={{ marginBottom: "1rem" }}
                            >
                              {type}
                            </button>
                          ))}
                        {selectedPayType === "Card" &&
                          cards.slice(0, visibleCardsCount).map((card: any) => {
                            console.log("object", card);
                            return (
                              <CardComponent
                                key={card?.id}
                                card={card}
                                onSelect={setSelectedCard}
                                isSelected={selectedCard === card?.id}
                              />
                            );
                          })}

                        {selectedPayType === "Card" && cards.length === 0 && (
                          <p>Please Proceed to pay to add a new card</p>
                        )}

                        {selectedPayType === "Card" &&
                          cards.length > visibleCardsCount && (
                            <button
                              onClick={() => setVisibleCardsCount(cards.length)}
                              className={Styles.cardLoadMore}
                            >
                              Load All
                            </button>
                          )}

                        {selectedPayType === "Card" && visibleCardsCount > 3 && (
                          <button
                            onClick={() => setVisibleCardsCount(3)}
                            className={Styles.cardLoadMore}
                          >
                            Show Less
                          </button>
                        )}
                      </div>
                      <Button
                        onClick={() => {}}
                        className="blackOutline addCardBtn"
                        style={{ marginTop: "20px" }}
                        disabled={true}
                      >
                        <p className="bold m-0">{`+ Add ${selectedPayType}`}</p>
                      </Button>
                    </section>
                  </div>
                </div>
              </div>
              <div className="paymentContainer">
                {/* <div className="paymentTop">
                  <section>
                    <div className="couponheader">
                      <h3 className="bold xlSize">Apply Coupon</h3>
                      <p className="readMore">MY COUPONS</p>
                    </div>
                    <div
                      style={{ backgroundColor: "#f5f6f6" }}
                      className="inputBox applyBtn"
                    >
                      <Percentage />
                      <span
                        className="inputField applyCenter"
                        style={{
                          backgroundColor: "none !important",
                          background: "none !important",
                          boxShadow: "none !important",
                        }}
                      >
                        Enter Code
                      </span>
                      <button onClick={() => {}} className="applyText">
                        APPLY
                      </button>
                    </div>
                  </section>
                  <section>
                    <h3 className="bold xlSize">Apply OC Point</h3>
                    <div className="inputBox applyBtn useBtn">
                      <OCPoint />
                      <div className="inputField useCenter">
                        <p className="mainTitle">Saving BHD 100</p>
                        <p className="subTitle">From 100 OC balance</p>
                      </div>
                      <button onClick={() => {}} className="applyText">
                        USE
                      </button>
                    </div>
                  </section>
                </div> */}
                <div className="paymentBottom">
                  <section>
                    <div className="costHeader">
                      <h3 className="bold xlSize">Product Price</h3>
                      <h3 className="bold xlSize">
                        <span className="grayText">BHD</span>{" "}
                        <span>{billingSummary.productTotalAmount}</span>
                      </h3>
                    </div>
                    {billingSummary?.discountPercentage &&
                    billingSummary.discountPercentage > 0 ? (
                      <>
                        {/* <div className="costHeader">
                          <p>Product Discount (% {billingSummary.discountPercentage})</p>
                          <p className="semibold">
                            <span className="grayText">
                              {billingSummary.discountPercentage}
                              {"%"}
                            </span>
                          </p>
                        </div> */}
                        <div className="costHeader">
                          <p>
                            Product Discount ({" "}
                            {billingSummary.discountPercentage}% )
                          </p>
                          <p className="semibold">
                            <span className="grayText">
                              <b>-</b> BHD
                            </span>{" "}
                            <span>{billingSummary.discountAmountToPay}</span>
                          </p>
                        </div>{" "}
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="costHeader">
                      <p>Shipping Charges </p>
                      <p className="semibold">
                        <span className="grayText">BHD</span>{" "}
                        <span>{billingSummary.shippingFees}</span>
                      </p>
                    </div>

                    <div className="costHeader">
                      <p>Coupon Discount</p>
                      <p className="semibold">
                        <span className="grayText"> BHD</span> <span>0</span>
                      </p>
                    </div>

                    <div className="costHeader">
                      <p>VAT (@ {billingSummary.vatPercentage} %)</p>
                      <p className="semibold">
                        <span className="grayText">BHD</span>{" "}
                        <span>{billingSummary.vatAmountToPay}</span>
                      </p>
                    </div>
                  </section>
                  <Divider />
                  <section>
                    <div className="costHeader">
                      <h3 className="bold xlSize">Total Amount</h3>
                      <h3 className="bold xlSize">
                        <span className="grayText">BHD</span>{" "}
                        <span>{billingSummary.amountToPay}</span>
                      </h3>
                    </div>
                    {serviceData.service_adv_amount !== null && (
                      <>
                        <Divider />

                        <div className="costHeader">
                          <p className="grayText">
                            {" "}
                            Continue to accept our{" "}
                            <span
                              className="readMore"
                              onClick={() => ppSetModalVisible(true)}
                            >
                              {" "}
                              privacy policy
                            </span>{" "}
                            and{" "}
                            <span
                              className="readMore"
                              onClick={() => setModalVisible(true)}
                            >
                              terms & conditions.{" "}
                            </span>
                          </p>
                          <Button
                            shape="round"
                            className="buttonBG paySubmitBtn"
                            type="primary"
                            htmlType="button"
                            onClick={handleBooking}
                            disabled={isPaidSucess}
                            style={{
                              backgroundColor:
                                isPaidSucess === true ? "gray" : "",
                              borderColor: isPaidSucess ? "gray" : "",
                            }}
                          >
                            Pay BHD {billingSummary.amountToPay}
                          </Button>
                        </div>
                      </>
                    )}
                  </section>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <PrivacyPolicy
        open={ppModalVisible}
        setOpen={ppSetModalVisible}
        setisTCChecked={setisPPChecked}
      />
      <TermsAndConditions
        open={modalVisible}
        setOpen={setModalVisible}
        setisTCChecked={setisTCChecked}
      />
      {failedMessage == "" && (
        <Dialog
          open={isSuccessModalVisible}
          onClose={handleSuccessModalClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent
            className={styles.successModalContent}
            style={{
              background: `url(${confettiIMG})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backdropFilter: "blur(2px)",
            }}
          >
            <img src={tickPop} alt="" />
            <h2>Thank You!</h2>
            <p>Your booking & order is sent to service provider</p>
            {/* <p>You've received 100 OC point</p> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                type="primary"
                onClick={handleSuccessModalClose}
                style={{ marginTop: "24px" }}
              >
                Great!
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
      {failedMessage !== "" && (
        <Dialog
          open={isFailureModalVisible}
          onClose={handleFailureModalClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent
            className={styles.failedModalContent}
            style={{
              // background: `url(${confettiIMG})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backdropFilter: "blur(2px)",
            }}
          >
            <img src={FailedTick} alt="" />
            <h2>Payment Failed</h2>
            <p>{failedMessage}</p>
            {/* <p>You've received 100 OC point</p> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <Button
                type="primary"
                onClick={handleFailureModalClose}
                style={{ marginTop: "24px" }}
              >
                Go to Home
              </Button> */}
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
export default ProductBooking;
